@tailwind base;
@tailwind components;
@tailwind utilities;

/* Existing styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Existing styles */
.graph-container {
  height: 50vh;
  min-height: 300px;
}

@media (max-width: 768px) {
  .graph-container {
    height: 40vh;
    min-height: 250px;
  }
}

@media (max-width: 480px) {
  .graph-container {
    height: 30vh;
    min-height: 200px;
  }
}
